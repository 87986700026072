@charset "utf-8";

:root {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-primary: #0d6efd;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
        "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));

    --up-orange: #de5a27;
    --up-yellow: #f2971c;
    --up-nav-hover: #D97750;
    --up-grey: #d0d1d3;
    --up-font-family: -apple-system, "Noto Sans", "Helvetica Neue", Helvetica, "Nimbus Sans L", Arial, "Liberation Sans", "PingFang SC", "Hiragino Sans GB", "Noto Sans CJK SC", "Source Han Sans SC", "Source Han Sans CN", "Microsoft YaHei", "Wenquanyi Micro Hei", "WenQuanYi Zen Hei", "ST Heiti", SimHei, "WenQuanYi Zen Hei Sharp", sans-serif;
    --up-dark: #333;
    --up-gray-dark: #343a40;
    --up-light: #ececec;
    --up-white-half: #ffffff80;
    --up-light-half: #ececec80;

    --menu-lv1-height: 84px;
    --menu-lv2-height: 124px;

}

::-moz-selection {
    background: var(--up-yellow);
    color: #fff;
}

::selection {
    background: var(--up-yellow);
    color: #fff;
}

::-moz-selection {
    background: var(--up-yellow);
    color: #fff;
}

::-webkit-selection {
    background: var(--up-yellow);
    color: #fff;
}




* {
    padding: 0;
    margin: 0;
    -webkiit-box-sizing: border-box;
    box-sizing: border-box;
}

/* 懒加载前隐藏 */
img:not([src]):not([srcset]) {
    visibility: hidden;
}

img.lazy {
    opacity: 0;
}

img:not(.initial) {
    transition: opacity .5s;
}

img.initial,
img.loaded,
img.error {
    opacity: 1;
}

body {
    margin: 0;
    font-family: var(--up-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: var(--up-dark);
    background-color: #fff;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    margin: 0;
    padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
    display: block;
}

audio,
canvas,
progress,
video {
    display: inline-block;
    vertical-align: baseline;
}

input,
select,
textarea {
    font-size: 100%;
    outline-style: none;
}

input,
button {
    border: none medium;
    outline: none;
}

button,
html input[type="button"],
input[type="submit"] {
    cursor: pointer;
}

button[disabled],
html input[disabled] {
    cursor: default;
}

address,
caption,
cite,
code,
dfn,
em,
th,
var,
i {
    font-style: normal;
    font-weight: 500;
}

ol,
ul,
li,
dl,
dt,
dd {
    list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

abbr,
acronym {
    border: none;
    font-variant: normal;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

img {
    border: 0 none;
    vertical-align: middle;
    max-width: 100%;
}

caption,
th {
    text-align: left;
}

th {
    text-align: inherit;
}

sup {
    vertical-align: text-top;
}

sub {
    vertical-align: text-bottom;
}

del {
    text-decoration: line-through;
}

legend {
    color: #333;
}

fieldset {
    border: none;
}

iframe {
    display: block;
}

q:before,
q:after {
    content: '';
}

ins,
a {
    text-decoration: none;
    cursor: pointer;
    color: #333;
}

a:focus,
*:focus {
    outline: none;
}

.clearfix:before,
.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

.clearfix:after {
    clear: both;
    overflow: hidden;
}

.clearfix {
    zoom: 1;
    clear: both;
}

.clear {
    clear: both;
    display: block;
    overflow: hidden;
    height: 0;
    line-height: 0;
    font-size: 0;
}

.hide {
    display: none !important;
    visibility: hidden;
}

.block {
    display: block !important;
}

.tl {
    text-align: left;
}

.tc {
    text-align: center;
}

.tr {
    text-align: right;
}

.fl {
    float: left;
    display: inline;
}

.fr {
    float: right;
    display: inline;
}

.cb {
    clear: both;
}

.none {
    display: none;
}

.border0 {
    border: 0 !important;
}

input:focus,
textarea:focus {
    outline: none;
}

input,
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    display: block;
}


@media screen and (min-width:1200px) {
    body {
        font-size: 14px;
    }
}

@media screen and (min-width:992px) and (max-width:1199px) {
    body {
        font-size: 14px;
    }
}

@media screen and (min-width:767px) and (max-width:992px) {
    body {
        font-size: 14px;
    }
}


@media screen and (min-width:320px) and (max-width:767px) {
    body {
        font-size: 13px;
    }
}